<template>
  <v-card>
    <v-card-title class="text-h6">
      <v-row>
        <v-col cols="6">
          {{ item.company_name }} - {{ item.tracking_id }} -
          {{ item.customer_goods_barcode }}
        </v-col>
        <v-col cols="6">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancel">
              {{ $t("labels.cancel") }}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirm"
              v-if="isShowBtnConfirm"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-title>

    <v-col cols="12">
      <v-alert text dense color="info" border="left" style="font-size: 14px">
        {{ $t("labels.remove_basket_receipt_code") }}
      </v-alert>
    </v-col>

    <v-col cols="12" v-if="goods.status === 100">
      <v-alert color="error" text dense border="left">{{
        $t("labels.goods_status_stop")
      }}</v-alert>
    </v-col>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="2" v-if="goods && goods.url_images">
          <ImageViewer :url="goods.url_images" height="auto" width="100%" />
        </v-col>
        <v-col cols="12" :md="goods && goods.url_images ? 5 : 6">
          <v-row>
            <v-col cols="3"> {{ $t("labels.barcode") }}: </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ goods.customer_goods_barcode }}
            </v-col>
            <v-col cols="3"> {{ $t("labels.goods") }}: </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ goods.name }} | {{ goods.unit }}
            </v-col>
            <v-col cols="3"> {{ $t("labels.goods_description") }}: </v-col>
            <v-col cols="9" class="font-weight-bold">
              {{ goods.description }}
            </v-col>
            <v-col cols="3"> {{ $t("labels.size") }}: </v-col>
            <v-col cols="9" class="font-weight-bold text-uppercase">
              {{ goods.size }}
            </v-col>

            <template v-if="!goods.d_size && !goods.r_size && !goods.c_size">
              <v-col cols="4">
                <v-text-field
                  v-model.number="goods.new_d_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.d_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_1"
                  @keyup.enter="nextInputFocus(1)"
                  min="1"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="goods.new_r_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.r_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_2"
                  @keyup.enter="nextInputFocus(2)"
                  min="1"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="goods.new_c_size"
                  class="c-input-xs"
                  type="number"
                  :label="$t('labels.c_size')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_3"
                  @keyup.enter="nextInputFocus(3)"
                  min="1"
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="3"> {{ $t("labels.dxrxc_size") }}: </v-col>
              <v-col cols="9" class="font-weight-bold">
                {{ goods.d_size }}x{{ goods.r_size }}x{{ goods.c_size }}
              </v-col>
            </template>

            <v-col cols="8">
              <template v-if="goods.pre_weight">
                <v-row>
                  <v-col cols="4"> {{ $t("labels.weight_1") }}: </v-col>
                  <v-col cols="8" class="font-weight-bold">
                    <span class="pl-4"
                      >{{ goods.pre_weight }} {{ $t("labels.gram") }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="goods.new_pre_weight"
                      class="c-input-xs"
                      type="number"
                      :label="$t('labels.weight_2')"
                      dense
                      outlined
                      clearable
                      hide-details
                      ref="inputIndex_4"
                      @keyup.enter="nextInputFocus(4)"
                      min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model.number="goods.new_sub_barcode"
                      class="c-input-xs"
                      type="text"
                      :label="$t('labels.sub_barcode')"
                      dense
                      outlined
                      clearable
                      hide-details
                      ref="inputIndex_5"
                      @keyup.enter="nextInputFocus(5)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-col>

            <v-col cols="4">
              <v-switch
                v-model="goods.disable_fly"
                :disabled="!!goods.transport"
                :readonly="!!goods.transport"
                label="Cấm bay"
                class="mt-0"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-alert
                v-if="goods.comment"
                text
                dense
                color="warning"
                border="left"
                style="font-size: 14px"
              >
                <b>{{ $t("labels.note") }}: </b> {{ goods.comment }}
              </v-alert>
              <v-textarea
                v-else
                v-model="goods.new_comment"
                label="Comment"
                rows="4"
                dense
                outlined
                clearable
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" :md="goods && goods.url_images ? 5 : 6">
          <v-row>
            <v-col cols="12">
              {{ $t("labels.checked") }}:
              <span class="info--text text-h6">{{
                item.receipted_quantity
              }}</span>
              <span class="text-h6">/</span>
              <span class="error--text text-h6">{{
                item.request_quantity
              }}</span>
            </v-col>

            <template v-if="goods.process_exp_date">
              <v-col cols="3">
                <v-autocomplete
                  v-model="year"
                  :items="yearOptions"
                  class="c-input-xs"
                  :label="$t('labels.expired_year')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_6"
                  @change="nextInputFocus(6)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="month"
                  :items="monthOptions"
                  class="c-input-xs"
                  :label="$t('labels.expired_month')"
                  dense
                  outlined
                  clearable
                  hide-details
                  :disabled="!year"
                  ref="inputIndex_7"
                  @change="nextInputFocus(7)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="day"
                  :items="dayOptions"
                  :disabled="!month || !year"
                  class="c-input-xs"
                  :label="$t('labels.expired_day')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_8"
                  @change="nextInputFocus(8)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="lotTracking"
                  class="c-input-xs"
                  type="text"
                  :label="$t('labels.lot')"
                  dense
                  outlined
                  clearable
                  hide-details
                  ref="inputIndex_9"
                  @keyup.enter="nextInputFocus(9)"
                ></v-text-field>
              </v-col>
            </template>

            <v-col cols="12">
              <v-text-field
                v-model="barcode"
                class="c-input-xs mb-3"
                type="text"
                label="UID | IMEI"
                dense
                outlined
                clearable
                hide-details
                autofocus
                ref="inputIndex_10"
                @keyup.enter="scanBarcode"
              ></v-text-field>
              <v-card class="mx-auto" outlined>
                <v-list-item>
                  <v-list-item-content>
                    <div class="text-h6">
                      {{ $t("labels.check") }}: {{ scannedBarcodes.length }} /
                      100 <small>({{ $t("labels.max") }})</small>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                      <v-chip
                        v-for="b in scannedBarcodes"
                        :key="`barcode_${b}}`"
                        class="mr-1 mb-1"
                        color="primary"
                      >
                        {{ b }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="basketCode"
                class="c-input-xs"
                type="text"
                :label="labelBasketPosition"
                dense
                outlined
                clearable
                hide-details
                ref="inputIndex_10"
                @keyup.enter="scanBasket"
                :disabled="
                  (!goods.new_pre_weight && !goods.weight) ||
                  scannedBaskets.length > 0 ||
                  scannedLocations.length > 0
                "
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-card class="mx-auto" outlined>
                <v-list-item v-if="scannedBaskets.length > 0">
                  <v-list-item-content>
                    <div class="">
                      {{ $t("labels.scanned_basket") }}:
                      <v-chip
                        v-for="b in scannedBaskets"
                        :key="`basket_${b.id}}`"
                        class="mr-1 mb-1"
                        color="primary"
                        small
                      >
                        {{ b.code }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="scannedLocations.length > 0">
                  <v-list-item-content>
                    <div class="">
                      {{ $t("labels.scanned_location") }}:
                      <v-chip
                        v-for="b in scannedLocations"
                        :key="`cell_${b.id}}`"
                        class="mr-1 mb-1"
                        color="primary"
                        small
                      >
                        {{ b.cell_id }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="
                    scannedBaskets.length === 0 && scannedLocations.length === 0
                  "
                >
                  <v-list-item-content>
                    <div class="">{{ $t("labels.scanned") }}:</div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox
        v-if="dialogConfirmRemove"
        :title="$t('labels.remove_basket')"
        :description="$t('labels.scan_basket_and_confirm_to_remove')"
        :label="$t('labels.remove_basket_and_confirm')"
        :placeholder="$t('labels.remove_basket_and_confirm')"
        :codes="removeCodes"
        @cancel="cancelConfirmRemove"
        @confirm="confirmRemove"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import checkSpecialGoodsMixins from "@/components/pos_goods/mixins/checkSpecialGoodsMixins";

export default {
  name: "CheckSpecialGoods",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    ConfirmRemoveBox: () => import("@/components/common/ConfirmRemoveBox"),
  },
  mixins: [checkSpecialGoodsMixins],
};
</script>

<style scoped></style>
